/* eslint-disable prefer-const */
import React, { useState, useEffect, useRef } from 'react';
//@ts-ignore
import * as styles from './navigation.module.css';
//@ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { MediaQueries } from '../Global/GlobalMediaQueries';
import CloseButton from '../Global/CloseButton';
import {
    renderNavIconCard,
    renderStandardLink,
    renderRichTextCard,
    renderContentCard,
} from './secondaryNavElements';
import { SiteSearch } from './SiteSearch';
import {
    NavigationProps,
    DesktopNavElements,
    MobileNavElements,
    navIconCard,
    navContentCard,
    navRichTextCard,
    navStandardLink,
    navColorConfig,
    NavGroup,
    LongLogo,
    ShortLogo,
} from './index';
import {
    getColor,
    getIcon,
    getMediaData,
    getMultiChoice,
} from '../Global/DataUtils/index';
import ContactUsOverlay from '../ContactUsOverlay';
import SvgArrowLgRight from '../Global/ArrowLgButton/SvgArrowLgRight';
import SvgArrowLgLeft from '../Global/ArrowLgButton/SvgArrowLgLeft';
import CountrySelectOverlay from '../CountrySelectOverlay';
import { createDomMotionComponent } from 'framer-motion';
import { defaultDomainCountryCodes } from '../../global/countries';
import { Link } from 'gatsby';

const motion = {
    div: createDomMotionComponent('div'),
    section: createDomMotionComponent('section'),
};

interface Props extends NavigationProps {
    preferredLanguage?: string;
}

const Navigation: React.FC<Props> = ({
    contact_us_icon,
    search_icon,
    shop_cta_icon,
    shop_cta_location,
    shop_mobile_label,
    account_cta_icon,
    account_cta_location,
    account_mobile_label,
    main_nav_icon,
    color_configuration,
    contact_us_overlay_configuration,
    long_logo,
    short_logo,
    groups,
    country_select_overlay_configuration,
    preferredLanguage,
    ...props
}) => {
    const [activePanel, setActivePanel] = useState(groups.value.length);
    const [navOpen, setNavOpen] = useState(false);
    const [navScroll, setNavScroll] = useState(false);
    const navFlag = true;
    const [contactUsOpen, setContactUsOpen] = useState(false);
    const [countrySelectOpen, setCountrySelectOpen] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedTerm, setDebouncedTerm] = useState('');
    const breakpoints = useBreakpoint();
    const bottomNavRef = useRef(null);
    const navRef = useRef(null);
    const prevActivePanelRef = useRef(activePanel);
    const homeHref =
        !preferredLanguage ||
        defaultDomainCountryCodes.indexOf(preferredLanguage.toLowerCase()) > -1
            ? '/'
            : '/' + preferredLanguage?.toLowerCase();
    const useCountryCode = homeHref.split('/')[1] !== '' ? true : false;

    useEffect(() => {
        if (activePanel < prevActivePanel || !navOpen) {
            setSearchTerm('');
        }

        prevActivePanelRef.current = activePanel;
    }, [activePanel, navOpen]);

    let prevActivePanel = prevActivePanelRef.current;

    const authorStyles = setAuthorStyles(color_configuration);

    const [navigationStyles, setNavigationStyles] = useState(
        authorStyles.inline
    );

    // use intersection observer to set nav scroll
    const handleBottomNavIntersection = (
        entries: IntersectionObserverEntry[]
    ) => {
        if (navOpen) return;
        const [entry] = entries;
        setNavScroll(!entry.isIntersecting);
    };

    const bottomNavIntersectionOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            handleBottomNavIntersection,
            bottomNavIntersectionOptions
        );

        if (bottomNavRef === null) return;

        if (bottomNavRef && bottomNavRef?.current) {
            //@ts-ignore
            observer.observe(bottomNavRef.current);
        }

        return () => {
            if (bottomNavRef && bottomNavRef?.current) {
                //@ts-ignore
                observer.unobserve(bottomNavRef.current);
            }
        };
    }, [navOpen]);

    // change inlineStyle if user opens navigation before scrolling
    useEffect(() => {
        const getNavigationStyles = () => {
            if (navScroll) return;

            return authorStyles.inline;
        };

        let newNavigationStyles = getNavigationStyles();
        if (newNavigationStyles) {
            setNavigationStyles(newNavigationStyles);
        }
    }, [navOpen]);

    // handles click outside
    useEffect(() => {
        if (!navRef?.current) {
            return;
        }

        const handleClickOutside = (e: MouseEvent) => {
            if (!navOpen || !navRef?.current) return;

            if (
                navOpen &&
                !navRef?.current?.contains(e.target) &&
                !countrySelectOpen
            ) {
                toggleActive(activePanel);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [navRef, navOpen]);

    // Update debouncedTerm after 2 seconds
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedTerm(searchTerm);
        }, 2000);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    // Update query params when debouncedTerm changes
    useEffect(() => {
        if (debouncedTerm) {
            const url = new URL(window.location);
            url.searchParams.set('search', debouncedTerm);
            window.history.pushState({}, '', url);
        }
    }, [debouncedTerm]);

    const iconColor = navScroll || navOpen ? '#505251' : authorStyles.color;
    const accentColor =
        navScroll || navOpen ? '#f06623' : authorStyles.accentColor;
    const findmysaunagray = navScroll ? styles.greySaunaScrolled : '';
    let showLogoImage = setLogoImage(
        long_logo,
        short_logo,
        breakpoints,
        navScroll
    );

    // close navigation if window size changes
    useEffect(() => {
        setNavOpen(false);
        setActivePanel(groups.value.length);
    }, [breakpoints]);

    const toggleActive = (idx: number) => {
        if (navOpen) {
            switch (idx) {
                case groups.value.length:
                    setNavOpen(!navOpen);
                    return;
                case activePanel:
                    setNavOpen(false);

                    setActivePanel(groups.value.length);
                    return;
            }
        }
        setActivePanel(idx);
        setNavOpen(true);
    };
    function handleToggleNavbar() {
        toggleActive(groups.value.length);
    }

    const searchIcon = getIcon(search_icon);
    const shopIcon = getIcon(shop_cta_icon);
    const accountIcon = getIcon(account_cta_icon);
    const contactUsIcon = getIcon(contact_us_icon);
    const mainNavIcon = getIcon(main_nav_icon);

    const iconStyles = {
        maskRepeat: 'no-repeat',
        maskPosition: 'center',
    };

    const shopIconStyles = {
        backgroundImage: `url(${shopIcon.url})`,
        maskImage: `url(${shopIcon.url})`,
        WebkitMaskImage: `url(${shopIcon.url})`,
        ...iconStyles,
    };

    const searchIconStyles = {
        backgroundImage: `url(${searchIcon.url})`,
        maskImage: `url(${searchIcon.url})`,
        WebkitMaskImage: `url(${searchIcon.url})`,
        cursor: 'pointer',
        ...iconStyles,
    };

    const accountIconStyles = {
        backgroundImage: `url(${accountIcon.url})`,
        maskImage: `url(${accountIcon.url})`,
        WebkitMaskImage: `url(${accountIcon.url})`,
        ...iconStyles,
    };

    const contactUsIconStyles = {
        backgroundImage: `url(${contactUsIcon.url})`,
        maskImage: `url(${contactUsIcon.url})`,
        WebkitMaskImage: `url(${contactUsIcon.url})`,
        ...iconStyles,
    };

    const mainNavIconStyles = {
        maskImage: `url(${mainNavIcon.url})`,
        WebkitMaskImage: `url(${mainNavIcon.url})`,
        ...iconStyles,
    };

    const renderMobileNavFooter = () => {
        const year = new Date().getFullYear();

        return (
            <div className={styles.mobileNavFooter}>
                {shop_cta_location?.value && (
                    <div className={styles.mobileFooterIconLink}>
                        <a
                            id={`footer__${
                                shop_mobile_label?.value?.replace(/ /g, '-') ||
                                'Shop'
                            }`}
                            href={shop_cta_location.value}
                            target="_new"
                        >
                            <span
                                className={styles.mobileFooterIcon}
                                style={shopIconStyles}
                            ></span>{' '}
                            {shop_mobile_label?.value || 'Shop'}
                        </a>
                    </div>
                )}
                {account_cta_location?.value && (
                    <div className={styles.mobileFooterIconLink}>
                        <a
                            id={`footer__${
                                account_mobile_label?.value?.replace(
                                    / /g,
                                    '-'
                                ) || 'Shop'
                            }`}
                            href={account_cta_location.value}
                            target="_new"
                        >
                            <span
                                className={styles.mobileFooterIcon}
                                style={accountIconStyles}
                            ></span>{' '}
                            {account_mobile_label?.value || 'Account'}
                        </a>
                    </div>
                )}
                {/* TODO: Change text to show country and language from countrySelectOverlay */}
                {country_select_overlay_configuration?.value[0] && (
                    <div>
                        <button
                            onClick={() => setCountrySelectOpen(true)}
                            className={styles.countrySelectButton}
                        >
                            <CountrySelectOverlay
                                {...country_select_overlay_configuration
                                    .value[0]?.elements}
                            />
                        </button>
                    </div>
                )}
                <div>
                    {props.mobile_copyright_copy?.value} {year}
                </div>
            </div>
        );
    };

    const handleSearchUserIntent = () => {
        setActivePanel(groups.value.length + 1);
    };

    const renderDesktopNavPanel = () => {
        if (activePanel === groups.value.length + 1) {
            return (
                <SiteSearch
                    toggleNavbar={handleToggleNavbar}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    goBackAction={() => setActivePanel(groups.value.length)}
                    authorStyles={authorStyles}
                    preferredLanguage={preferredLanguage || 'default'}
                    ariaLabel={props.aria_label?.value}
                    keywordHintText={props.keyword_hint_text?.value}
                    searchPanelTitle={props.search_panel_title?.value}
                    desktopSearchCtaLabel={
                        props.desktop_search_cta_label?.value
                    }
                />
            );
        }

        const navPanel = groups.value[activePanel];

        if (!navPanel) return null;
        const panelName = navPanel.elements.category_name;
        return (
            <div className={styles.group}>
                {navPanel.elements.desktop_elements.value.map(
                    (element: DesktopNavElements, idx: number) => {
                        const cardAuthorStyles = {
                            color: iconColor,
                            accentColor: accentColor,
                        };

                        switch (element.system.type) {
                            case 'nav___icon_card':
                                return (
                                    <React.Fragment key={idx}>
                                        {renderNavIconCard(
                                            element as navIconCard,
                                            cardAuthorStyles,
                                            panelName
                                        )}
                                    </React.Fragment>
                                );
                            case 'nav___content_card_with_image':
                                return (
                                    <React.Fragment key={idx}>
                                        {renderContentCard(
                                            element as navContentCard,
                                            cardAuthorStyles,
                                            panelName
                                        )}
                                    </React.Fragment>
                                );
                            case 'nav___rich_text_card': {
                                const modifiedPanelName = panelName.value
                                    ? {
                                          value:
                                              'navigation__' + panelName.value,
                                      }
                                    : undefined;
                                return (
                                    <React.Fragment key={idx}>
                                        {renderRichTextCard(
                                            element as navRichTextCard,
                                            modifiedPanelName
                                        )}
                                    </React.Fragment>
                                );
                            }
                        }
                    }
                )}
            </div>
        );
    };

    const renderMobileNavPanel = () => {
        if (activePanel === groups.value.length) {
            return (
                <>
                    <ul className={styles.mobilePrimaryNav}>
                        {groups.value
                            .filter(
                                (group) =>
                                    group.elements?.iscta?.value[0]?.name !==
                                    'Yes'
                            )
                            .map((group, idx) => {
                                return (
                                    <li key={idx}>
                                        {group.elements?.display_name?.value &&
                                        group.elements?.cta_location ? (
                                            <a
                                                id={`navigation__${group.elements?.display_name?.value?.replace(
                                                    / /g,
                                                    '-'
                                                )}`}
                                                className={
                                                    styles.directLinkMobile
                                                }
                                                href={`${
                                                    useCountryCode
                                                        ? homeHref
                                                        : ''
                                                }${
                                                    group.elements?.anchor
                                                        ?.value
                                                        ? group.elements
                                                              .cta_location
                                                              ?.value[0]
                                                              ?.elements?.slug
                                                              ?.url +
                                                          '#' +
                                                          group.elements.anchor
                                                              .value
                                                        : group.elements
                                                              .cta_location
                                                              ?.value[0]
                                                              ?.elements?.slug
                                                              ?.url
                                                }`}
                                                target={
                                                    getMultiChoice(
                                                        group.elements
                                                            .cta_window_action
                                                    ) === 'same_window'
                                                        ? undefined
                                                        : '_blamk'
                                                }
                                            >
                                                <button
                                                    className={
                                                        styles.mobileButtonLink
                                                    }
                                                >
                                                    <span
                                                        className={
                                                            styles.buttonCopy
                                                        }
                                                    >
                                                        {
                                                            group.elements
                                                                ?.display_name
                                                                ?.value
                                                        }
                                                    </span>
                                                </button>
                                            </a>
                                        ) : (
                                            <button
                                                onClick={() =>
                                                    setActivePanel(idx)
                                                }
                                                className={
                                                    styles.mobileButtonLink
                                                }
                                                id={`navigation__${group.elements?.category_name?.value?.replace(
                                                    / /g,
                                                    '-'
                                                )}`}
                                            >
                                                <span
                                                    className={
                                                        styles.buttonCopy
                                                    }
                                                >
                                                    {
                                                        group.elements
                                                            ?.category_name
                                                            ?.value
                                                    }
                                                </span>
                                                <div
                                                    className={
                                                        styles.arrowContainer
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.forward
                                                        }
                                                    >
                                                        <SvgArrowLgRight
                                                            strokeColor={
                                                                iconColor
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </button>
                                        )}
                                    </li>
                                );
                            })}
                        <li className={styles.mobileSearch}>
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSearchUserIntent();
                                }}
                                className={styles.searchForm}
                            >
                                <div
                                    className={styles.navIcon}
                                    style={searchIconStyles}
                                >
                                    <span className="sr-only">Search</span>
                                </div>
                                <input
                                    placeholder={props.keyword_hint_text.value}
                                    value={searchTerm}
                                    onChange={(e) =>
                                        setSearchTerm(e.target.value)
                                    }
                                />
                            </form>
                        </li>
                    </ul>
                    {renderMobileNavFooter()}
                </>
            );
        }

        if (activePanel === groups.value.length + 1) {
            return (
                <SiteSearch
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    goBackAction={() => setActivePanel(groups.value.length)}
                    authorStyles={authorStyles}
                    backLabel="back"
                    preferredLanguage={preferredLanguage || 'default'}
                    footer={renderMobileNavFooter()}
                    ariaLabel={props.aria_label?.value}
                    keywordHintText={props.keyword_hint_text?.value}
                    searchPanelTitle={props.search_panel_title?.value}
                    desktopSearchCtaLabel={
                        props.desktop_search_cta_label?.value
                    }
                />
            );
        }

        const navPanel = groups.value[activePanel];
        const panelName = navPanel.elements.category_name;

        return (
            <>
                <div className={styles.mobileSecondaryNav}>
                    <button
                        onClick={() => setActivePanel(groups.value.length)}
                        className={
                            styles.mobileButtonLink +
                            ' ' +
                            styles.mobileBackLink
                        }
                    >
                        <div className={styles.arrowContainer}>
                            <div className={styles.back}>
                                <SvgArrowLgLeft strokeColor={iconColor} />
                            </div>
                        </div>

                        <span className={styles.buttonCopy}>
                            {navPanel.elements.category_name.value}
                        </span>
                    </button>
                </div>

                <div className={styles.group}>
                    {navPanel.elements.mobile_elements.value.map(
                        (element: MobileNavElements) => {
                            switch (element.system.type) {
                                case 'nav___icon_card':
                                    return renderNavIconCard(
                                        element as navIconCard,
                                        authorStyles,
                                        panelName
                                    );
                                case 'nav___standard_link':
                                    return renderStandardLink(
                                        element as navStandardLink,
                                        panelName
                                    );
                            }
                        }
                    )}
                </div>
                {renderMobileNavFooter()}
            </>
        );
    };

    const desktopPanel = {
        open: {
            height: 'auto',
            transition: {
                duration: 0.5,
                damping: 100,
            },
        },
        closed: {
            height: '96px',
            transition: {
                duration: 0.5,
                delay: 0.1,
                damping: 100,
            },
        },
    };

    const renderExpandNav = () => {
        return (
            <motion.div className={styles.expandNav}>
                {breakpoints.xl
                    ? renderDesktopNavPanel()
                    : renderMobileNavPanel()}
            </motion.div>
        );
    };

    const renderTopMenuIcons = () => {
        return (
            <ul className={styles.navIcons}>
                {breakpoints.xl && (
                    <>
                        <li
                            onClick={() =>
                                toggleActive(groups.value.length + 1)
                            }
                        >
                            <span
                                id={
                                    'navigation__' +
                                    search_icon.value[0].elements.icon_svg_file.value[0].name
                                        .replace(/ /g, '-')
                                        .split('.')[0]
                                }
                                onClick={handleSearchUserIntent}
                                className={styles.navIcon}
                                style={searchIconStyles}
                            >
                                <span className="sr-only">Search</span>
                            </span>
                        </li>
                        {shop_cta_location?.value && (
                            <li>
                                <a
                                    id={
                                        'navigation__' +
                                        shop_cta_icon.value[0].elements.icon_svg_file.value[0].name
                                            .replace(/ /g, '-')
                                            .split('.')[0]
                                    }
                                    href={shop_cta_location.value}
                                    className={styles.navIcon}
                                    style={shopIconStyles}
                                    target="_new"
                                >
                                    <span className="sr-only">
                                        {shop_mobile_label?.value || 'Shop'}
                                    </span>
                                </a>
                            </li>
                        )}
                        {account_cta_location?.value && (
                            <li>
                                <a
                                    id={
                                        'navigation__' +
                                        account_cta_icon.value[0].elements.icon_svg_file.value[0].name
                                            .replace(/ /g, '-')
                                            .split('.')[0]
                                    }
                                    href={account_cta_location.value}
                                    target="_new"
                                    className={styles.navIcon}
                                    style={accountIconStyles}
                                >
                                    <span className="sr-only">
                                        {account_mobile_label?.value ||
                                            'Account'}
                                    </span>
                                </a>
                            </li>
                        )}
                    </>
                )}

                {contact_us_overlay_configuration?.value[0] && (
                    <li>
                        <button
                            onClick={() => setContactUsOpen(true)}
                            className={styles.navIcon}
                            style={contactUsIconStyles}
                        >
                            <span className="sr-only">Contact Us</span>
                        </button>
                    </li>
                )}
                {country_select_overlay_configuration.value[0] && (
                    <li className={styles.flagIcon}>
                        <CountrySelectOverlay
                            navFlag={navFlag}
                            {...country_select_overlay_configuration.value[0]
                                ?.elements}
                        />
                    </li>
                )}
                {!breakpoints.xl && (
                    <li>
                        {navOpen ? (
                            <div className={styles.closeButton}>
                                <CloseButton onClick={handleToggleNavbar} />
                            </div>
                        ) : (
                            <button
                                id="navigation__Open-Menu"
                                onClick={handleToggleNavbar}
                                className={styles.navIcon}
                                style={mainNavIconStyles}
                            >
                                <span className="sr-only">Open Menu</span>
                            </button>
                        )}
                    </li>
                )}
            </ul>
        );
    };

    return (
        <>
            {contactUsOpen && contact_us_overlay_configuration?.value[0] && (
                <ContactUsOverlay
                    isOpen={contactUsOpen}
                    toggle={(status) => setContactUsOpen(status)}
                    {...contact_us_overlay_configuration.value[0]?.elements}
                />
            )}
            {/* {countrySelectOpen &&
            country_select_overlay_configuration.value[0] ? (
                <CountrySelectOverlay
                    changeStatus={(status) => setCountrySelectOpen(status)}
                    {...country_select_overlay_configuration.value[0]?.elements}
                    activeStatus={countrySelectOpen}
                />
            ) : null} */}
            <motion.section
                initial={'closed'}
                variants={desktopPanel}
                animate={navOpen ? 'open' : 'closed'}
                className={`${styles.navigation} ${
                    navScroll || navOpen ? styles.navDefault : ''
                } ${navOpen ? styles.higherZindex : ''} ${
                    breakpoints.xl ? styles.hideOverflow : ''
                }`}
                style={navScroll || navOpen ? undefined : navigationStyles}
                ref={navRef}
            >
                {authorStyles.color && (
                    <style>
                        {`
                        .${styles.navIcon} { 
                        background-color: ${iconColor} !important;
                        }
                        .${styles.closeButton} path {
                            stroke: ${iconColor} !important;
                        }
                        .${styles.closeButton}:hover path {
                            stroke: ${accentColor} !important;
                        }
                        .${styles.navIcon}:hover {
                            background-color: ${accentColor} !important;
                        }
                        .${styles.categoryName}:hover, .${styles.mobileButtonLink}:hover, .${styles.standardLink}:hover {
                            color: ${accentColor}
                        }
                        .${styles.mobileButtonLink}:hover .${styles.arrowContainer} path { stroke: ${accentColor} !important}

                        .${styles.contentCardLink}:hover div.${styles.cta} { 
                            border-color: ${accentColor} !important; 
                            color: ${iconColor} !important;
                            background-color: ${accentColor} !important;}
                        `}
                    </style>
                )}
                <div className={styles.navContainer}>
                    <div className={styles.collapseNav}>
                        <a
                            href={homeHref}
                            target="_self"
                            id="navigation__Sunlighten-logo"
                        >
                            <img
                                src={showLogoImage}
                                className={styles.logoImage}
                                alt="Sunlighten"
                            />
                        </a>
                        <ul>
                            {breakpoints.xl &&
                                groups.value.map(
                                    (group: NavGroup, idx: number) => {
                                        let uniqueID = group.elements
                                            ?.category_name?.value
                                            ? group.elements?.category_name
                                                  ?.value
                                            : group.elements?.display_name
                                                  ?.value;
                                        uniqueID = uniqueID?.replace(/ /g, '-');
                                        return (
                                            <li
                                                id={`navigation__${uniqueID}`}
                                                key={idx}
                                                onClick={
                                                    group.elements
                                                        ?.display_name &&
                                                    group.elements?.element
                                                        ?.value
                                                        ? (e) => {
                                                              e.preventDefault();
                                                          }
                                                        : group.elements
                                                              ?.display_name
                                                              ?.value &&
                                                          group.elements
                                                              ?.cta_location
                                                        ? () => {}
                                                        : () =>
                                                              toggleActive(idx)
                                                }
                                                className={`${
                                                    group.elements?.iscta
                                                        ?.value[0].name == 'Yes'
                                                        ? `${findmysaunagray} ${styles.findMySaunaCta}`
                                                        : styles.categoryName
                                                }`}
                                                style={
                                                    idx === activePanel
                                                        ? {
                                                              color: authorStyles.accentColor,
                                                          }
                                                        : undefined
                                                }
                                            >
                                                {group.elements?.display_name
                                                    ?.value &&
                                                group.elements?.element ? (
                                                    <Link
                                                        to={`${
                                                            useCountryCode
                                                                ? homeHref
                                                                : ''
                                                        }${
                                                            group.elements
                                                                ?.element
                                                                ?.value[0]
                                                                ?.elements?.slug
                                                                ?.url
                                                        }`}
                                                    >
                                                        {
                                                            group?.elements
                                                                ?.display_name
                                                                ?.value
                                                        }
                                                    </Link>
                                                ) : group.elements?.display_name
                                                      ?.value &&
                                                  group.elements
                                                      ?.cta_location ? (
                                                    <a
                                                        href={`${
                                                            useCountryCode
                                                                ? homeHref
                                                                : ''
                                                        }${
                                                            group.elements
                                                                ?.anchor?.value
                                                                ? group.elements
                                                                      .cta_location
                                                                      ?.value[0]
                                                                      ?.elements
                                                                      ?.slug
                                                                      ?.url +
                                                                  '#' +
                                                                  group.elements
                                                                      .anchor
                                                                      .value
                                                                : group.elements
                                                                      .cta_location
                                                                      ?.value[0]
                                                                      ?.elements
                                                                      ?.slug
                                                                      ?.url
                                                        }`}
                                                        target={
                                                            getMultiChoice(
                                                                group.elements
                                                                    .cta_window_action
                                                            ) === 'same_window'
                                                                ? undefined
                                                                : '_blamk'
                                                        }
                                                    >
                                                        {
                                                            group.elements
                                                                .display_name
                                                                .value
                                                        }
                                                    </a>
                                                ) : (
                                                    group.elements
                                                        ?.category_name?.value
                                                )}
                                            </li>
                                        );
                                    }
                                )}
                            <li>{renderTopMenuIcons()}</li>
                        </ul>
                    </div>
                    {navOpen && renderExpandNav()}
                </div>
            </motion.section>
            <div
                ref={bottomNavRef}
                style={{ position: 'absolute', top: '30%' }}
            ></div>
            {navOpen && (
                <div
                    onClick={() => {
                        setNavOpen(!navOpen);
                    }}
                    className={styles.modalOverlay}
                ></div>
            )}
        </>
    );
};

export default Navigation;

function setLogoImage(
    long_logo: LongLogo,
    short_logo: ShortLogo,
    breakpoints: MediaQueries,
    navScroll: boolean
) {
    if (
        (!breakpoints.xl || navScroll) &&
        short_logo.value[0]?.elements.file.value[0]?.url
    ) {
        const shortLogo = getMediaData(short_logo);
        return shortLogo?.url;
    }

    const longLogo = getMediaData(long_logo);
    return longLogo?.url;
}

function setAuthorStyles(color_configuration: navColorConfig) {
    const colorConfig = color_configuration.value[0]?.elements;

    if (!colorConfig) {
        return {
            backgroundColor: '#ffffff',
            color: '#505251',
            accentColor: '#f06623',
            inline: {
                backgroundColor: '#ffffff',
                color: '#505251',
            },
        };
    }

    const backgroundColor = getColor(colorConfig.background_color) || '#ffffff';
    const backgroundOpacity = colorConfig.background_opacity.value / 100 || 1;

    const fontColor = getColor(colorConfig.font_color) || '#505251';

    const accentColor =
        backgroundColor === '#f06623' || fontColor === '#f06623'
            ? '#505251'
            : '#f06623';

    const backgroundRgba = hexToRgba(backgroundColor, backgroundOpacity);
    const backgroundStyle =
        backgroundOpacity === 1
            ? { background: backgroundColor }
            : {
                  backgroundImage: `linear-gradient(rgba(${backgroundRgba}), rgba(100, 100, 100, 0))`,
              };

    return {
        backgroundColor: backgroundColor,
        color: fontColor,
        accentColor: accentColor,
        inline: {
            color: fontColor,
            ...backgroundStyle,
        },
    };
}

function hexToRgba(hex: string, opacity: number) {
    hex = hex.replace(/#/g, '');
    if (hex.length === 3) {
        hex = hex
            .split('')
            .map(function (hex) {
                return hex + hex;
            })
            .join('');
    }
    let result = /^([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})[\da-z]{0,0}$/i.exec(
        hex
    );
    if (!result) {
        return null;
    }
    let red = parseInt(result[1], 16);
    let green = parseInt(result[2], 16);
    let blue = parseInt(result[3], 16);

    return [red, green, blue, opacity].join(', ');
}
