import React, { useState, useEffect, useContext } from 'react';
//@ts-ignore
import * as styles from './navigation.module.css';
import {
    InstantSearch,
    connectInfiniteHits,
    Configure,
    connectSearchBox,
} from 'react-instantsearch-dom';
//@ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import SvgArrowLgLeft from '../Global/ArrowLgButton/SvgArrowLgLeft';
import { AuthorStyles } from './index';
import { KontentMedia, KontentRichText } from '../../../types/KontentProps';
import ShowMoreArrow from '../Global/ShowMoreArrow';
import ArrowLgButton from '../Global/ArrowLgButton';
import { searchClient } from '../Global/DataUtils/algolia';
import { PageMetadataContext } from '../../templates/PageMetaDataContext';
import parse from 'html-react-parser';

import {
    countriesList,
    defaultDomainCountryCodes,
} from '../../global/countries';
import CloseButton from '../Global/CloseButton';

export interface SiteSearchProps {
    searchTerm?: string;
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
    toggleNavbar: () => void;
    goBackAction: () => void;
    authorStyles: AuthorStyles;
    backLabel?: string;
    preferredLanguage: string;
    footer?: JSX.Element;
    ariaLabel: string;
    keywordHintText: string;
    searchPanelTitle: string;
    desktopSearchCtaLabel: string;
}

interface InfiniteHitsProps {
    hits: Hit[];
    hasPrevious: boolean;
    hasMore: boolean;
    refinePrevious: () => any;
    refineNext: () => any;
    authorStyles: AuthorStyles;
    ctaLabel?: string;
    urlPrefix: string;
}

interface SearchBoxProps {
    currentRefinement: string;
    refine: (event: any) => any;
    isSearchStalled: boolean;
    onChange?: (event: any) => any;
    ariaLabel?: string;
    keywordHintText?: string;
}

interface Hit {
    objectId: string;
    slug: string;
    url: string;
    display_page_name: string;
    seo_page_title: string;
    page_search_summary: string;
    page_search_image: KontentMedia['value'];
    page_type: string;
    blog_topic: string;
    preferred_language: string;
    answer?: KontentRichText;
}
const parseHtml = (htmlAsString: string) => {
    let html = htmlAsString;
    html = html.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
    html = html.replace(/\n/g, '');
    const faq = parse(html);
    return faq;
};
export const SiteSearch: React.FC<SiteSearchProps> = ({
    searchTerm,
    setSearchTerm,
    ...props
}) => {
    const pageMetaData = useContext(PageMetadataContext);
    const preferredLanguage = pageMetaData.preferredLanguage;
    const urlPrefix =
        props.preferredLanguage == 'default'
            ? ''
            : `/${props.preferredLanguage.toLowerCase()}`;
    const breakpoints = useBreakpoint();
    const indexName = `New_Prod_MasterPages_v1_${preferredLanguage}`;

    const handleSearchBoxChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSearchTerm(event.currentTarget.value);
    };

    return (
        <InstantSearch
            indexName={indexName}
            searchClient={searchClient}
            refresh={false}
        >
            <Configure hitsPerPage={4} />
            {!breakpoints.xl && (
                <div className={styles.mobileSecondaryNav}>
                    <button
                        onClick={props.goBackAction}
                        className={
                            styles.mobileButtonLink +
                            ' ' +
                            styles.mobileBackLink
                        }
                    >
                        <div className={styles.arrowContainer}>
                            <div className={styles.back}>
                                <SvgArrowLgLeft strokeColor="#505251" />
                            </div>
                        </div>
                        <span className={styles.buttonCopy}>
                            {props.searchPanelTitle}
                        </span>
                    </button>
                </div>
            )}

            {breakpoints.xl && props.searchPanelTitle && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <div className={styles.searchTitle}>
                        {props.searchPanelTitle}
                    </div>
                    <div className={styles.closeButton}>
                        <CloseButton onClick={props.toggleNavbar} />
                    </div>
                </div>
            )}

            <div className={styles.searchInput}>
                <label htmlFor="search" className="sr-only">
                    {props.ariaLabel}
                </label>
                <CustomSearchBox
                    defaultRefinement={searchTerm}
                    onChange={handleSearchBoxChange}
                    ariaLabel={props.ariaLabel}
                    keywordHintText={props.keywordHintText}
                />
            </div>

            {props.authorStyles.accentColor && breakpoints.xl ? (
                <style>{`.${styles.contentCardLink}:hover div.${styles.cta} { 
                        border-color: ${props.authorStyles.accentColor} !important; 
                        color: ${props.authorStyles.color} !important;
                        background-color: ${props.authorStyles.accentColor} !important;
                    }`}</style>
            ) : null}

            <CustomHits
                authorStyles={props.authorStyles}
                ctaLabel={props.desktopSearchCtaLabel}
                urlPrefix={urlPrefix}
            />
            {props.footer}
        </InstantSearch>
    );
};

const InfiniteHits: React.FC<InfiniteHitsProps> = ({
    hits,
    hasMore,
    hasPrevious,
    refineNext,
    refinePrevious,
    authorStyles,
    urlPrefix,
    ...props
}) => {
    const breakpoints = useBreakpoint();
    const [currentHit, setCurrentHit] = useState(
        breakpoints.xl && hits.length - 4
    );

    const [visibleHits, setVisibleHits] = useState(
        breakpoints.xl
            ? hits.length > 4
                ? hits.slice(currentHit, currentHit + 4)
                : hits
            : hits
    );
    useEffect(() => {
        let newHit = breakpoints.xl && hits.length - 4;
        setCurrentHit(newHit);
        setVisibleHits(
            breakpoints.xl
                ? hits.length > 4
                    ? hits.slice(currentHit, currentHit + 4)
                    : hits
                : hits
        );
    }, [breakpoints, hits]);

    const inlineStyle: React.CSSProperties = {};

    if (authorStyles.color) {
        inlineStyle.color = authorStyles.color;
        inlineStyle.borderColor = authorStyles.color;
    }

    const renderHit = (hit: Hit, idx: number) => {
        if (hit.slug == 'empower' && hit?.url) {
            const pageMetadata = useContext(PageMetadataContext);

            let splittedPagePath = hit?.url?.split('/');
            let splittedPagePathCountryCode = hit?.url?.split('/')[1];
            //@ts-ignore
            const saunaPagePathContainsCountrySlug: boolean =
                countriesList.indexOf(splittedPagePathCountryCode) > -1
                    ? true
                    : false;
            if (saunaPagePathContainsCountrySlug) {
                let pagePathWithoutCountryCode =
                    '/' + splittedPagePath.slice(2).join('/');
                hit.url = pagePathWithoutCountryCode;
            }
            if (
                pageMetadata?.preferredLanguage &&
                defaultDomainCountryCodes.indexOf(
                    pageMetadata?.preferredLanguage?.toLowerCase()
                ) == -1
            ) {
                urlPrefix =
                    '/' + pageMetadata?.preferredLanguage?.toLowerCase();
            }
        }

        if (breakpoints.xl) {
            const image = hit?.page_search_image?.[0]?.elements?.file?.value[0];
            const pageMetadata = useContext(PageMetadataContext);

            if (
                hit.slug == 'empower' &&
                hit?.url &&
                pageMetadata?.preferredLanguage
            ) {
                let splittedPagePath = hit?.url?.split('/');
                let splittedPagePathCountryCode = hit?.url?.split('/')[1];
                //@ts-ignore
                const saunaPagePathContainsCountrySlug: boolean =
                    countriesList.indexOf(splittedPagePathCountryCode) > -1
                        ? true
                        : false;
                if (saunaPagePathContainsCountrySlug) {
                    let pagePathWithoutCountryCode =
                        '/' + splittedPagePath.slice(2).join('/');
                    hit.url = pagePathWithoutCountryCode;
                }
                if (
                    defaultDomainCountryCodes.indexOf(
                        pageMetadata?.preferredLanguage?.toLowerCase()
                    ) == -1
                ) {
                    urlPrefix =
                        '/' + pageMetadata?.preferredLanguage?.toLowerCase();
                }
            }
            return (
                <>
                    {hit?.question ? (
                        <div
                            className={styles.contentCard}
                            key={'hit' + idx}
                            style={
                                visibleHits.length < 4 && breakpoints.xl
                                    ? { marginRight: '4rem' }
                                    : {}
                            }
                        >
                            <a
                                href={`${urlPrefix}/customer-service/frequently-asked-questions/?faqID=${hit?.objectID}`}
                                className={styles.contentCardLink}
                            >
                                <div
                                    className={styles.headline}
                                    style={{ color: authorStyles.accentColor }}
                                >
                                    {hit.question}
                                </div>
                                <div className={styles.description}>
                                    {hit?.answer &&
                                    hit?.answer !== '<p><br></p>'
                                        ? parseHtml(
                                              hit.answer.substring(0, 150) +
                                                  '....'
                                          )
                                        : ''}
                                </div>
                                <div className={styles.cta}>{'READ MORE'}</div>
                                {/* <div className={styles.cta}>
                                    {props.ctaLabel}
                                </div> */}
                            </a>
                        </div>
                    ) : (
                        <div
                            className={styles.contentCard}
                            key={'hit' + idx}
                            style={
                                visibleHits.length < 4 && breakpoints.xl
                                    ? { marginRight: '4rem' }
                                    : {}
                            }
                        >
                            <a
                                href={`${urlPrefix}${hit.url}`}
                                target="_self"
                                className={styles.contentCardLink}
                            >
                                {image && (
                                    <img
                                        src={image.url}
                                        alt={image?.description}
                                    />
                                )}
                                <div
                                    className={styles.headline}
                                    style={{ color: authorStyles.accentColor }}
                                >
                                    {hit.seo_page_title
                                        ? hit.seo_page_title
                                        : hit.display_page_name}
                                </div>
                                <div className={styles.description}>
                                    {hit.page_search_summary}
                                </div>
                                <div className={styles.cta}>
                                    {props.ctaLabel}
                                </div>
                            </a>
                        </div>
                    )}
                </>
            );
        }

        return (
            <div className={styles.standardLink}>
                <a
                    href={
                        hit?.question
                            ? `${urlPrefix}/customer-service/frequently-asked-questions/?faqID=${hit?.objectID}`
                            : `${urlPrefix}${hit.url}`
                    }
                    target="_self"
                >
                    {hit?.question
                        ? hit.question
                        : hit.seo_page_title
                        ? hit.seo_page_title
                        : hit.display_page_name}
                </a>
            </div>
        );
    };

    const handlePrevClick = () => {
        let newHit = currentHit - 4;

        if (newHit < 0) newHit = 0;
        setCurrentHit(newHit);
        if (newHit === hits.length && hasPrevious) refinePrevious();
        setVisibleHits(hits.slice(newHit, newHit + 4));
    };

    const handleNextClick = () => {
        let newHit = currentHit + 4;
        setCurrentHit(newHit);

        if (newHit === hits.length && hasMore) refineNext();
        setVisibleHits(hits.slice(newHit, newHit + 4));
    };

    return (
        <div className={styles.searchResults}>
            <div
                className={styles.group + ' ' + styles.searchGroup}
                style={
                    visibleHits.length < 4 && breakpoints.xl
                        ? { justifyContent: 'flex-start' }
                        : {}
                }
            >
                {visibleHits.map((hit: Hit, idx: number) =>
                    renderHit(hit, idx)
                )}

                {hasMore && !breakpoints.xl && (
                    <ShowMoreArrow
                        btnText=""
                        handleClick={refineNext}
                        strokeColor="#505251"
                        transform="0"
                    />
                )}
            </div>
            {currentHit > 0 && breakpoints.xl && (
                <ArrowLgButton
                    strokeColor={authorStyles.accentColor}
                    direction="left"
                    handleClick={handlePrevClick}
                    className={styles.prev}
                />
            )}

            {(hasMore || currentHit < hits.length - 4) && breakpoints.xl && (
                <ArrowLgButton
                    strokeColor={authorStyles.accentColor}
                    direction="right"
                    handleClick={handleNextClick}
                    className={styles.next}
                />
            )}
        </div>
    );
};

const CustomHits = connectInfiniteHits(InfiniteHits);

const SearchBox: React.FC<SearchBoxProps> = ({
    currentRefinement,
    refine,
    onChange,
    ariaLabel,
    keywordHintText,
}) => {
    return (
        <input
            type="search"
            value={currentRefinement}
            onChange={(event) => {
                if (onChange) {
                    onChange(event);
                }
                refine(event.currentTarget.value);
            }}
            aria-label={ariaLabel || 'Search'}
            name="search"
            placeholder={keywordHintText || ''}
        />
    );
};

const CustomSearchBox = connectSearchBox(SearchBox);
